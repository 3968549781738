<template>
  <div>
    <el-form
      :model="form"
      ref="logFilterForm"
      label-width="70px"
      size="medium"
      class="filter-from"
    >
      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="平台码：" prop="code">
          <el-input
            v-model="form.code"
            placeholder="请输入"
            @keyup.enter.native="getSearchList"
          >
            <p
              slot="suffix"
              class="search-slot el-icon-search"
              @click="getSearchList"
            ></p>
          </el-input>
        </el-form-item>
        <el-form-item label="组织名称：" prop="name">
          <el-input
            v-model="form.name"
            placeholder="请输入"
            @keyup.enter.native="getSearchList"
          >
            <p
              slot="suffix"
              class="search-slot el-icon-search"
              @click="getSearchList"
            ></p>
          </el-input>
        </el-form-item>
        <el-form-item label="所在地区：" prop="address">
          <el-cascader
            clearable
            size="medium"
            :props="{ value: 'label' }"
            @change="getSearchList"
            v-model="form.address"
            :options="cityJson"
          ></el-cascader>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="组织标识：" prop="type">
          <el-select
            clearable
            v-model="form.type"
            placeholder="请选择"
            @change="getSearchList"
          >
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
    <div class="total-title">共查询到 {{ list.length }} 条数据</div>

    <el-scrollbar
      class="scroll-view"
      v-loadmore="LoadMore"
      ref="scrollBar"
      v-loading="Loading"
    >
      <div class="scroll-box">
        <div class="main-item" v-for="(item, index) in list">
          <div class="item-left">
            <el-image
              class="list-image"
              :src="fixImageUrl(item.logo)"
              fit="cover"
            ></el-image>
            <div class="item-content">
              <p class="content-item" v-if="item.name">{{ item.name }}</p>
              <p class="content-item" v-if="item.address">{{ item.address }}</p>
              <p class="content-item" v-if="item.type">{{ item.type }}</p>
            </div>
          </div>
          <div class="item-right">
            <el-button
              size="small"
              :disabled="item.status !== -1"
              :type="item.status === -1 ? 'primary' : 'default'"
              @click="handleRelation(item)"
            >
              {{ statusArr[item.status + 1] }}</el-button
            >
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
const regionData = require("../assets/jsons/address.json");
import { searchList } from "../api/gov-list";
export default {
  components: {},
  data() {
    return {
      Loading: false,
      form: {
        code: "",
        name: "",
        type: "",
        address: "",
      },
      list: [],
      statusArr: ["申请关联", "已提交，等待审核", "已关联"],
      //   组织标识配置
      typeOptions: [
        {
          value: 0,
          label: "无",
        },
        {
          value: 1,
          label: "志愿服务组织",
        },
        {
          value: 2,
          label: "行业协会商会",
        },
        {
          value: 3,
          label: "慈善组织",
        },
        {
          value: 4,
          label: "公开募捐资格",
        },
      ],
      // 城市数据
      cityJson: regionData,
    };
  },
  methods: {
    // 地址改变
    handleAddressChange() {
      console.log(this.form.address);
    },
    // 获取关联列表
    getSearchList() {
      let isNull = true;

      let postData = JSON.parse(JSON.stringify(this.form));
      if (postData.address.length) {
        postData.address = postData.address.join("/");
      } else {
        postData.address = "";
      }
     
      //  检查有没有筛选条件
      Object.values(postData).forEach((item) => {
        console.log(item);
        if (item !== "") {
          isNull = false;
        }
      });
      if (isNull) {
        // 没有任何搜索条件，清空 list
        this.list = [];
        return;
      }
      if (postData.type === "") {
        postData.type = -1;
      }

      this.Loading = true;

      searchList(postData)
        .then((res) => {
          this.list = res.data.list;
          this.Loading = false;
        })
        .catch((err) => {
          this.Loading = false;
        });
    },
    // 触底刷新
    LoadMore() {
      return;
      // console.log("触底刷新");
    },
    // 点击关联
    handleRelation(res) {
      this.searchCode = "";
      this.list = [];
      this.$emit("handleRelation", res);
    },
    // 重置滚动条位置
    resetScroll() {
      if (this.$refs.scrollBar) this.$refs.scrollBar.wrap.scrollTop = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .filter-from .filter-item .el-form-item__content input {
  //   min-width: 185px;
}

.total-title {
  margin-bottom: 10px;
}
.scroll-view {
  height: 300px;
  margin: 0 auto;
}
.search-slot {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  width: 50px;
  height: 100%;
  // color: #fff;
  // background-color: #3576ff;
  cursor: pointer;
}
.scroll-box {
  padding: 10px;
  .main-item {
    box-shadow: 0 0 10px #eeeeee;
    border-radius: 4px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item-left {
      display: flex;
      align-items: center;
      .list-image {
        width: 120px;
        height: 80px;
      }
      .item-content {
        margin-left: 16px;
        height: 80px;

        display: flex;
        flex-direction: column;
        justify-content: space-around;
        flex: 1;
        .content-item {
          color: #1a1a1a;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
