<template>
  <div class="list-page">
    <el-button class="top-btn" size="small" type="primary" @click="handleApply">申请关联</el-button>
    <!-- <list-filter v-model="filterForm" :uploadFilter="ok" /> -->
    <list-tabs class="table-tab" style="margin-top: 0" v-model="filterForm.tab" :isLoading="loading"
      :tabs="tabArr" @tab-click="handleClick">
    </list-tabs>
    <div class="list-content" v-loading="loading">
      <el-table :data="tbody" class="thead-light" stripe style="width: 100%" @sort-change="sortChange">
        <!-- 勾选  @selection-change="handleSelectionChange"-->
        <!-- <el-table-column class="select-check" fixed type="selection" width="55"></el-table-column> -->
        <!-- 操作 -->
        <el-table-column label="操作" :width="180" fixed="right">
          <template slot-scope="{row}">
            <el-button v-if="filterForm.tab === 'normal'" size="small" type="text"
              @click="handleEditAuth(row)">
              修改授权</el-button>
            <el-button v-if="filterForm.tab === 'normal'" size="small" type="text"
              @click="handleStopAuth(row)">
              停止授权</el-button>
            <el-button v-if="filterForm.tab === 'checking'" size="small" type="text"
              @click="handleCancelApply(row.code)">取消申请</el-button>
            <el-button v-if="filterForm.tab === 'unchecked'" size="small" type="text"
              @click="handleRepply(row)">
              重新申请
            </el-button>
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead[filterForm.tab]">
          <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label"
            :min-width="th.minWidth || ''" :sortable="th.sortable" :key="index">
            <template slot-scope="{row}">
              <!-- 平台LOGO -->
              <el-image v-if="th.prop === 'logo'" :src="fixImageUrl(row[th.prop])" class="list-image"
                fit="cover" @click="$previewImage([row[th.prop]])"></el-image>
              <!-- 授权权限 -->
              <div v-else-if="th.prop === 'permission_texts'">
                <div class="tabel-list" v-for="(item, index) in row[th.prop]" :key="index">
                  <div class="list-dot"></div>{{item}}
                </div>
              </div>
              <!-- 普通 -->
              <span v-else>{{ row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <ApplyDialog v-model="openApplyDialog" />
    <EditApplyDialog v-model="openEditApplyDialog" :currentItem="currentItem"  @reflashList="reflashList"/>

    <!-- <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage" /> -->
  </div>
</template>

<script>
import listFilter from "../components/ListFilter";
import ApplyDialog from "../components/ApplyDialog";
import EditApplyDialog from "../components/EditApplyDialog";

// import Pagination from "@/base/components/Default/Pagination";
import { govList, cancelApply, apply} from "../api/gov-list";
export default {
  data() {
    return {
      tabArr: [
        { label: "已关联", name: "normal" },
        { label: "待审核", name: "checking", badge: 1 },
        { label: "未通过", name: "unchecked" },
      ],
      loading: false, //加载
      thead: {
        normal: [
          { label: "LOGO", prop: "logo", minWidth: 110 },
          { label: "平台名称", prop: "name", minWidth: 110 },
          { label: "平台简介", prop: "description", minWidth: 150 },
          {
            label: "平台码",
            prop: "code",
            minWidth: 130,
          },
          { label: "授权权限", prop: "permission_texts", minWidth: 200 },
          {
            label: "授权时间",
            prop: "check_time",
            minWidth: 160,
            sortable: true,
          },
        ],
        checking: [
          { label: "LOGO", prop: "logo", minWidth: 110 },
          { label: "平台名称", prop: "name", minWidth: 110 },
          { label: "平台简介", prop: "description", minWidth: 150 },
          {
            label: "平台码",
            prop: "code",
            minWidth: 120,
          },
          { label: "授权权限", prop: "permission_texts", minWidth: 150 },
          {
            label: "提交时间",
            prop: "apply_time",
            minWidth: 160,
            sortable: true,
          },
        ],
        unchecked: [
          { label: "LOGO", prop: "logo", minWidth: 110 },
          { label: "平台名称", prop: "name", minWidth: 110 },
          { label: "平台简介", prop: "description", minWidth: 150 },
          {
            label: "平台码",
            prop: "code",
            minWidth: 130,
          },
          { label: "授权权限", prop: "permission_texts", minWidth: 150 },
          {
            label: "提交时间",
            prop: "apply_time",
            minWidth: 160,
            sortable: true,
          },
          { label: "未通过原因", prop: "failed_reason", minWidth: 150 },
        ],
      },
      openEditApplyDialog:false,
      //筛选对象
      filterForm: {
        tab: "normal", //当前筛选tab
        // page_size: 15, //每页数据量
      },
      //筛选缓存
      filters: {
        normal: null,
        checking: null,
        unchecked: null,
      },
      // pageData: {
      //   page_size: 15,
      // },
      tbody: [],
      // selectArr: [], //勾选的数组id
      openApplyDialog: false, // 申请关联弹窗
      openRelationDialog: false, // 关联平台弹窗
      RelationData: {}, // 关联平台信息
      currentItem:{}
    };
  },
  methods: {
    // 获取精神列表
    getGovList(pageData) {
      this.tbody = [];
      // this.pageData = {};
      this.loading = true;
      govList(pageData)
        .then((res) => {
          const { data } = res;
          this.tbody = data;
          // this.pageData = data;
          this.filters[data.tab] = { ...this.filterForm };
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 申请关联
    handleApply() {
      this.openApplyDialog = true;
    },
    // 停止授权
    handleStopAuth({ name, code }) {
      this.$msgbox
        .confirm(`停止授权后 “${name}” 将无法继续获取你的平台信息`, "提示", {
          type: "info",
        })
        .then((res) => {
          this.loading = true;
          cancelApply({ code, is_cancel: 0 })
            .then((res) => {
              this.$message.success(res.msg);
              this.getGovList(this.filterForm);
            })
            .catch((err) => {
              this.getGovList(this.filterForm);
            });
        })
        .catch((err) => {});
    },
    // 修改授权
    handleEditAuth(item) {
      console.log(item)
      this.currentItem = item
      this.openEditApplyDialog= true
       
    },
    // 取消申请
    handleCancelApply(code) {
      this.$msgbox
        .confirm("确定要取消申请吗？", "提示", {
          type: "info",
        })
        .then((res) => {
          this.loading = true;
          cancelApply({ code, is_cancel: 1 })
            .then((res) => {
              this.$message.success(res.msg);
              this.getGovList(this.filterForm);
            })
            .catch((err) => {
              this.getGovList(this.filterForm);
            });
        })
        .catch((err) => {});
    },
    // 重新申请
    handleRepply(row) {
      this.loading = true;
      apply({ code: row.code, permissions: row.permissions })
        .then((res) => {
          this.$message.success(res.msg);
          this.getGovList(this.filterForm);
        })
        .catch((err) => {
          this.getGovList(this.filterForm);
        });
    },
    // 勾选操作
    // handleSelectionChange(selection) {
    //   console.log(selection);
    //   if (!selection.length) {
    //     this.selectArr = [];
    //   } else {
    //     this.selectArr = [];
    //     // 筛选id
    //     selection.forEach((item) => {
    //       this.selectArr.push(item.id);
    //     });
    //   }
    // },
    // 切换tab栏
    handleClick(e) {
      if (this.filters[e.name]) {
        this.filterForm = this.filters[e.name];
      } else {
        this.filterForm = {
          tab: e.name, //当前筛选tab
        };
      }
      this.getGovList(this.filterForm);
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getGovList(this.filterForm);
    },
    // 筛选回调
    ok(e) {
      const pageData = {};
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.getGovList({ ...this.filterForm, ...pageData });
    },
    // 刷新列表
    reflashList() {
      this.getGovList({
        page: 1,
        ...this.filterForm,
      });
    },
    // 分页查询
    // changPage(e) {
    //   this.filterForm.page_size = e.page_size;
    //   const pageData = { ...this.filterForm, ...e };
    //   this.getGovList(pageData);
    // },
  },
  created() {
    this.getGovList(this.filterForm); //获取列表数据
    this.$bus.on("handleRelation", (res) => {
      this.openApplyDialog = false;
      this.RelationData = res;
      this.openRelationDialog = true;
    });
    this.$bus.on("switchTab", (tab) => {
      this.handleClick({
        name: tab,
      });
    });
  },
  components: { listFilter, ApplyDialog,EditApplyDialog },
};
</script>

<style lang="scss" scoped>
.list-image {
  cursor: pointer;
  width: 80px;
  height: 80px;
}

.tabel-list {
  display: flex;
  align-items: center;
  .list-dot {
    margin-right: 8px;
    background-color: #606266;
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
</style>