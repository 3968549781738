<template>
  <div style="width: 100%">
    <div class="relation-explain">
      <p class="relation-title">正在申请关联 “{{ Data.name }}”</p>
      <p class="relation-title">关联说明</p>
      <p style="line-height: 20px">
        <span style="font-weight: bold; margin-right: 6px">·</span
        >勾选权限授权，允许 “{{ Data.name }}” 平台使用你的信息权限
      </p>
    </div>
    <div class="relation-table">
      <p class="relation-title">权限授权</p>
      <el-table
        v-loading="loading"
        :data="list"
        class="thead-light cursor-pointer"
        stripe
        style="width: 100%"
        @selection-change="handleSelectionChange"
        @row-click="handleCurrentChange"
        ref="multipleTable"
      >
        <!-- 勾选-->
        <el-table-column fixed type="selection" width="55"></el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column
            show-overflow-tooltip
            :prop="th.prop"
            :label="th.label"
            :min-width="th.minWidth || ''"
            :key="index"
          >
            <template slot-scope="{ row }">
              <!-- 普通 -->
              <span>{{ row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
      <div class="footer">
        <el-button type="primary" @click="nextStep" :loading="saveLoading"
          >上一步</el-button
        >
        <el-button type="primary" @click="submit" :loading="saveLoading"
          >提交申请</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { applyInfoList, apply } from "../api/gov-list";

export default {
  components: {},
  props: {
    Data: Object,
  },
  data() {
    return {
      loading: false,
      saveLoading: false,
      instructions: "", // 关联说明
      list: [],
      thead: [
        { label: "权限名称", prop: "name", minWidth: 120 },
        { label: "详细介绍", prop: "description", minWidth: 160 },
      ],
      selectArr: [],
    };
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(value) {
        this.$emit("closeDialog", value);
      },
    },
  },
  created() {
    this.getApplyInfoList();
  },
  methods: {
    nextStep() {
      this.$emit("nextStep");
    },
    // 获取权限列表
    getApplyInfoList() {
      this.loading = true;
      applyInfoList()
        .then((res) => {
          this.instructions = res.data.instructions_list;
          this.list = res.data.permissions;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 点击数据项操作
    handleCurrentChange(val) {
      this.list.forEach((element) => {
        if (val.code === element.code) {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(element);
          });
        }
      });
    },
    // 勾选列表数据
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.code);
        });
      }
    },
    // 提交申请
    submit() {
      this.saveLoading = true;
      let data = {
        code: this.Data.code,
        permissions: this.selectArr,
      };
      apply(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.saveLoading = false;
          this.$refs.multipleTable.clearSelection();
          this.$emit("apply");
        })
        .catch((err) => {
          this.saveLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.relation-title {
  font-size: 16px;
  font-weight: bold;
  margin: 12px 0;
}

.relation-table,
.relation-explain {
  width: 90%;
  margin: 0 auto;
  .footer {
    margin-top: 10px;
    text-align: center;
  }
}
</style>
