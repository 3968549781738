<!--
 * @Author: dongjia
 * @Date: 2021-09-13 17:49:37
 * @LastEditTime: 2021-10-13 14:15:44
 * @LastEditors: aleaner
 * @Description: 申请关联弹窗
 * @FilePath: \saas-admin-vue\src\modules\gov-admin\components\ApplyDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog
    title="申请关联"
    :visible.sync="openDialog"
    width="900px"
    class="dialog-vertical"
    append-to-body
    @close="closeDialog"
  >
    <!-- 头部 -->

    <Steps
      v-model="step"
      :totalSteps="totalSteps"
      :stepsLabel="stepsLabel"
      :totalWidth="800"
      :descMaxWidth="100"
    />
    <!-- 搜索协会 -->
    <template v-if="step === 1">
      <ApplyAssociation @handleRelation="handleRelation" />
    </template>
    <!-- 关联协会 -->
    <template v-if="step === 2">
      <RelationAssociation :Data="RelationData" @apply="apply" @nextStep="nextStep" />
    </template>
  </el-dialog>
</template>

<script>
import Steps from "./Steps.vue";
import ApplyAssociation from "./ApplyAssociation.vue";
import RelationAssociation from "./RelationAssociation.vue";
export default {
  name: "AddLiveDialog",
  model: {
    prop: "open",
    event: "closeDialog",
  },
  components: {
    Steps,
    ApplyAssociation,
    RelationAssociation,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 步骤条
      step: 1,
      // 实际开始的步骤条位置
      startStep: 1,
      totalSteps: 2,
      stepsLabel: [
        { name: "1.搜索协会", show: true },
        { name: "2.关联协会", show: true },
      ],
      // 要关联的协会数据
      RelationData: {},
    };
  },
  watch: {
    openDialog(val) {
      if (!val) {
        // this.list = [];
        // this.searchCode = "";
      }
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(value) {
        this.$emit("closeDialog", value);
      },
    },
  },
  created() {
    // this.getSearchList();
  },
  methods: {
    nextStep() {
      this.step = 1;
    },
    // 关联协会
    handleRelation(data) {
      this.RelationData = data;
      this.step = 2
    },
    // 关联成功
    apply() {
      this.openDialog = false;
      this.$bus.emit("switchTab", "checking");
    },
    closeDialog() {
      this.step = 1;
    }
  },
};
</script>

<style lang="scss" scoped>
.dialog-vertical {
  ::v-deep.el-dialog__body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  ::v-deep.el-input__inner {
    border-radius: 0px;
  }

  ::v-deep.el-input__suffix {
    right: 0px;
  }

  ::v-deep.el-scrollbar__wrap {
    overflow-x: hidden;
  }

  ::v-deep.el-scrollbar__view {
    &:last-child {
      margin-bottom: 20px;
    }
  }
}
</style>
