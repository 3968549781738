<!--
 * @Author: dongjia
 * @Date: 2021-09-14 14:13:55
 * @LastEditTime: 2021-11-29 09:51:59
 * @LastEditors: aleaner
 * @Description: 关联平台弹窗
 * @FilePath: \saas-admin-vue\src\modules\gov-admin\components\RelationDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog
    title="修改权限"
    :visible.sync="openDialog"
    width="650px"
    class="dialog-vertical"
    append-to-body
    @open="openFn"
  >
    <div class="relation-table">
      <p class="relation-title">权限授权</p>
      <el-table
        v-loading="loading"
        :data="list"
        class="thead-light cursor-pointer"
        stripe
        style="width: 100%"
        @selection-change="handleSelectionChange"
        @row-click="handleCurrentChange"
        ref="multipleTable"
      >
        <!-- 勾选-->
        <el-table-column fixed type="selection" width="55"></el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column
            show-overflow-tooltip
            :prop="th.prop"
            :label="th.label"
            :min-width="th.minWidth || ''"
            :key="index"
          >
            <template slot-scope="{ row }">
              <!-- 普通 -->
              <span>{{ row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <span slot="footer">
      <el-button
        size="small"
        type="primary"
        @click="submit"
        :loading="saveLoading"
        >修改授权</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { applyInfoList, changeAuth } from "../api/gov-list";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    currentItem: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      saveLoading: false,
      instructions: "", // 关联说明
      list: [],
      thead: [
        { label: "权限名称", prop: "name", minWidth: 120 },
        { label: "详细介绍", prop: "description", minWidth: 160 },
      ],
      selectArr: [],
    };
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(value) {
        this.$emit("closeDialog", value);
      },
    },
  },
  created() {
    this.getApplyInfoList();
    console.log(this.selectArr);
  },
  methods: {
    openFn() {
      this.getApplyInfoList();
    },
    // 获取权限列表
    getApplyInfoList() {
      this.loading = true;
      applyInfoList()
        .then((res) => {
          this.instructions = res.data.instructions_list;
          this.list = res.data.permissions;
          this.$nextTick(() => {
            // for (let i = 0; i < this.fileUploadData.length; i++) {
            //   this.$refs.fileTable.toggleRowSelection(this.fileUploadData[i]);
            // }
            this.selectArr = this.currentItem.permissions || [];

            this.selectArr.forEach((item, index) => {
              this.list.forEach((key, i) => {
                if (item === key.code) {
                  this.$refs.multipleTable.toggleRowSelection(key);
                }
              });
            });
          });
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 点击数据项操作
    handleCurrentChange(val) {
      this.list.forEach((element) => {
        if (val.code === element.code) {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(element);
          });
        }
      });
    },
    // 勾选列表数据
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.code);
        });
      }
    },
    // 提交申请
    submit() {
      this.saveLoading = true;
      let data = {
        code: this.currentItem.code,
        permissions: this.selectArr,
      };

      changeAuth(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.saveLoading = false;
          this.$emit("reflashList");
          this.openDialog = false;
        })
        .catch((err) => {
          this.saveLoading = false;
        });
    },
  },
  watch: {
    currentItem: {
      handler(val) {},
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-vertical {
  ::v-deep.el-dialog__body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.relation-title {
  font-size: 16px;
  font-weight: bold;
  margin: 12px 0;
}

.relation-table,
.relation-explain {
  width: 90%;
}
</style>
