import api from "@/base/utils/request";

// 获取品牌详情
export const govList = data => {
  return api({
    url: "/admin/admin/gov/index",
    method: "post",
    data
  });
};

// 关联列表搜索
export const searchList = data => {
  return api({
    url: "/admin/admin/gov/search2",
    method: "post",
    data
  });
};

// 获取权限授权列表
export const applyInfoList = data => {
  return api({
    url: "/admin/admin/gov/getApplyInfo",
    method: "post",
    data
  });
};
// 修改数据授权
export const changeAuth = data => {
  return api({
    url: "/admin/admin/gov/changeAuth",
    method: "post",
    data
  });
};

// 申请关联
export const apply = data => {
  return api({
    url: "/admin/admin/gov/apply",
    method: "post",
    data
  });
};

// 取消申请/停止授权
export const cancelApply = data => {
  return api({
    url: "/admin/admin/gov/cancelApply",
    method: "post",
    data
  });
};